<template>
  <div>
    <div class="c-section">
      <p>UCC公式オンラインストアと連携すると便利にお得にお買い物ができます。</p>
    </div>

    <div class="c-section c-section--mb32">
      <ValidationObserver
        ref="observerRef"
        tag="form"
        class="c-form"
        v-slot="{ valid }">
        <BaseInput
          v-model="form.id"
          type="email"
          label="オンラインストアID"
          placeholder="メールアドレスを入力してください"
          :vee-props="veeProps.id" />

        <BaseInput
          v-model="form.password"
          type="password"
          label="パスワード"
          placeholder="パスワードを入力してください"
          :vee-props="veeProps.password" />

        <BaseCheckbox v-model="form.agreement" :vee-props="veeProps.agreement">
          <!-- prettier-ignore -->
          <a :href="$customUrlScheme.page('standalone', 'url=/terms')">利用規約</a>
          に同意する
        </BaseCheckbox>

        <Button
          :style-type="valid && !isLoading ? 'secondary' : 'disabled'"
          fluid
          class="p-linkOnlineStore__action"
          :disabled="!valid || isLoading"
          v-text="isLoading ? '処理中...' : '連携する'"
          @click="link" />
      </ValidationObserver>
    </div>

    <div class="c-section p-linkOnlineStore__register">
      <h2 class="c-title6">オンラインストアへの新規登録はこちら</h2>
      <Button :href="$customUrlScheme.page('ec')" style-type="quaternary" fluid>
        新規登録する
      </Button>
      <p class="p-linkOnlineStore__notes">
        ※外部サイトにリンクします<br />
        ※新規登録後、アプリでの連携をお願いいたします<br />
        ※オンラインストアでユーザー情報を変更された場合は再度連携を行ってください
      </p>
    </div>
  </div>
</template>

<script>
import { SystemDialogMessage } from '@/constants/enums';

export default {
  data: () => ({
    form: {
      id: '',
      password: '',
      agreement: false
    },

    isLoading: false
  }),

  computed: {
    veeProps() {
      return {
        id: {
          name: 'オンラインストアID',
          rules: { required: true, email: true, max: 255 }
        },

        password: {
          name: 'パスワード',
          rules: { required: true, max: 255 }
        },

        agreement: {
          name: '利用規約',
          rules: { is: { other: true } },
          customMessages: { is: '利用規約への同意は必須です。' }
        }
      };
    }
  },

  methods: {
    async link() {
      if (this.isLoading) return;
      if (!(await this.$refs.observerRef.validate())) return;

      try {
        this.isLoading = true;

        const { data } = await this.$repositories('customer').loginEcCustomer({
          id: this.form.id,
          password: this.form.password
        });
        this.$emit('linked', data);
      } catch {
        window.location.href = this.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
