var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "c-section c-section--mb32" },
      [
        _c("ValidationObserver", {
          ref: "observerRef",
          staticClass: "c-form",
          attrs: { tag: "form" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (ref) {
                var valid = ref.valid
                return [
                  _c("BaseInput", {
                    attrs: {
                      type: "email",
                      label: "オンラインストアID",
                      placeholder: "メールアドレスを入力してください",
                      "vee-props": _vm.veeProps.id,
                    },
                    model: {
                      value: _vm.form.id,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "id", $$v)
                      },
                      expression: "form.id",
                    },
                  }),
                  _c("BaseInput", {
                    attrs: {
                      type: "password",
                      label: "パスワード",
                      placeholder: "パスワードを入力してください",
                      "vee-props": _vm.veeProps.password,
                    },
                    model: {
                      value: _vm.form.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "password", $$v)
                      },
                      expression: "form.password",
                    },
                  }),
                  _c(
                    "BaseCheckbox",
                    {
                      attrs: { "vee-props": _vm.veeProps.agreement },
                      model: {
                        value: _vm.form.agreement,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "agreement", $$v)
                        },
                        expression: "form.agreement",
                      },
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.$customUrlScheme.page(
                              "standalone",
                              "url=/terms"
                            ),
                          },
                        },
                        [_vm._v("利用規約")]
                      ),
                      _vm._v(" に同意する "),
                    ]
                  ),
                  _c("Button", {
                    staticClass: "p-linkOnlineStore__action",
                    attrs: {
                      "style-type":
                        valid && !_vm.isLoading ? "secondary" : "disabled",
                      fluid: "",
                      disabled: !valid || _vm.isLoading,
                    },
                    domProps: {
                      textContent: _vm._s(
                        _vm.isLoading ? "処理中..." : "連携する"
                      ),
                    },
                    on: { click: _vm.link },
                  }),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "c-section p-linkOnlineStore__register" },
      [
        _c("h2", { staticClass: "c-title6" }, [
          _vm._v("オンラインストアへの新規登録はこちら"),
        ]),
        _c(
          "Button",
          {
            attrs: {
              href: _vm.$customUrlScheme.page("ec"),
              "style-type": "quaternary",
              fluid: "",
            },
          },
          [_vm._v(" 新規登録する ")]
        ),
        _vm._m(1),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "c-section" }, [
      _c("p", [
        _vm._v(
          "UCC公式オンラインストアと連携すると便利にお得にお買い物ができます。"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "p-linkOnlineStore__notes" }, [
      _vm._v(" ※外部サイトにリンクします"),
      _c("br"),
      _vm._v(" ※新規登録後、アプリでの連携をお願いいたします"),
      _c("br"),
      _vm._v(
        " ※オンラインストアでユーザー情報を変更された場合は再度連携を行ってください "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }